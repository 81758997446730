import { useEffect, useState } from "react";
// for work_images
import strive from "../assets/images/work_images/strive.png";
import teamup from "../assets/images/work_images/teamup.png";
// import work3 from "../assets/images/work_images/3.jpg";
// import work4 from "../assets/images/work_images/4.jpg";
// import work5 from "../assets/images/work_images/5.jpg";
// import work6 from "../assets/images/work_images/6.jpg";
// import work7 from "../assets/images/work_images/7.jpg";
// import work8 from "../assets/images/work_images/8.jpg";
// import work9 from "../assets/images/work_images/9.jpg";
// works small images
// import workSmall1 from "../assets/images/work_images/small/1.jpg";
// import workSmall2 from "../assets/images/work_images/small/2.jpg";
// import workSmall3 from "../assets/images/work_images/small/3.jpg";
// import workSmall4 from "../assets/images/work_images/small/4.jpg";
// import workSmall5 from "../assets/images/work_images/small/5.jpg";
// import workSmall6 from "../assets/images/work_images/small/6.jpg";
// import workSmall7 from "../assets/images/work_images/small/7.jpg";
// import workSmall8 from "../assets/images/work_images/small/8.jpg";
// import workSmall9 from "../assets/images/work_images/small/9.jpg";
// blog post images
// import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/blog4.jpg";
import blog2 from "../assets/images/blog_images/blog2.jpg";
import blog1 from "../assets/images/blog_images/blog1.jpg";
import blog3 from "../assets/images/blog_images/blog3.jpg";
import blog5 from "../assets/images/blog_images/blog5.jpg";
// blog image small
// import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
// import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
// import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
// import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
// import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
// import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
// import icon2 from "../assets/images/icons/icon-3.svg";
// import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
// import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Web Development",
      title: "Strive",
      img: strive,
      imgSmall: strive,
      bg: "#FFF0F0",
      client: "Practice Project",
      langages: "ReactJs, Tailwind CSS",
      link: "https://strive-app.netlify.app/",
      github: "https://github.com/khaliltkhalil/strive",
      linkText: "www.strive-app.netlify.app",
      description:
        "A platform to track all your workouts. Technologies used: React, React-chartjs, Tailwind, json-server.",
    },
    {
      id: "2",
      tag: "Web Development",
      title: "Teamup",
      img: teamup,
      imgSmall: teamup,
      bg: "#FFF3FC",
      client: "Practice Project",
      langages: "ReactJs, Flask",
      link: "https://teamup-ptao.onrender.com/",
      linkText: "teamup-ptao.onrender.com",
      github: "https://github.com/khaliltkhalil/teamup",
      description:
        "A platform to manage and collaborate on projects.  Technologies used: React, Tailwind, Redux, Python, Flask, Flask-sqlalchemy.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blog1,
      date: "Mar 30, 2024",
      category: "SQL",
      title: "SQL NOT EXISTS Operator",
      bg: "#FCF4FF",
      link: "https://medium.com/@khalil.t.khalil/sql-not-exists-operator-a73870f37b1c",
      description: "",
    },
    {
      id: "2",
      img: blog2,
      imgSmall: blog2,
      date: "Oct 8, 2023",
      category: "JavaScript",
      title: "Promise in JavaScript",
      bg: "#EEFBFF",
      link: "https://medium.com/@khalil.t.khalil/promise-in-javascript-1b08623afc60",
      description: "",
    },
    {
      id: "3",
      img: blog3,
      imgSmall: blog3,
      date: "Aug 26, 2023",
      category: "Python",
      title: "Iterators and Iterable in Python",
      bg: "#FFF0F0",
      link: "https://medium.com/@khalil.t.khalil/iterator-and-iterable-in-python-6bee56d1ffc9",
      description: "",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blog4,
      date: "Jul 1, 2023",
      category: "React",
      title: "Optimizing React Apps Performance with useMemo",
      bg: "#FCF4FF",
      link: "https://medium.com/@khalil.t.khalil/optimizing-react-apps-performance-usememo-1e212855af2b",
      description: "",
    },
    {
      id: "5",
      img: blog5,
      imgSmall: blog5,
      date: "May 11, 2023",
      category: "JavaScript",
      title: "Closure in JavaScript",
      bg: "#FCF4FF",
      link: "https://medium.com/@khalil.t.khalil/closure-in-javascript-fd30cbda47b0",
      description: "",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    // {
    //   id: "05",
    //   name: "Contact",
    //   link: "/home/contact",
    //   icon: <RiContactsBookLine />,
    // },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/homeTwo/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/homeTwo/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/homeTwo/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/homeTwo/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/homeTwo/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // serviceArray items for about pageSS
  const serviceArray = [
    {
      id: "1",
      icon: icon,
      title: "Front-end Development",
      des: "Build responsive and fast front-end apps using React, Tailwind, and Redux",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "Mobile App",
      des: "Build native mobile app using React Native",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon4,
      title: "Back-end Development",
      des: "Develope Scalable easily maintained back-end services using NodeJs and ExpressJs",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "Jan/2023-Oct/2023",
      title: "Software Development Bootcamp",
      place: "Flatiron School",
      bg: "#FFF4F4",
    },

    {
      id: 2,
      date: "Aug/2014-May/2017",
      title: "MS Electrical Engineering",
      place: "University of Houston",
      bg: "#FFF1FB",
    },

    {
      id: 3,
      date: "2006-2011",
      title: "BS Electrical Engineering",
      place: "Tishreen University",
      bg: "#FFF4F4",
    },
  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "2024-present",
      title: "Full-stack Software Developer",
      place: "Immigrant budddies",
      bg: "#EEF5FA",
    },

    {
      id: 2,
      date: "2023-present",
      title: "Full-stack Software Developer",
      place: "Freelancing",
      bg: "#F2F4FF",
    },
  ];
  // awardsArray items for Resume page
  const awardsArray = [
    {
      id: 1,
      date: "2015-2017",
      title: "  Graphic Designer",
      place: "Web Graphy, Los Angeles, CA",
      bg: "#FCF4FF",
    },

    {
      id: 2,
      date: "2014 - 2015",
      title: "Jr. Web Developer",
      place: "Creative Gigs.",
      bg: "#FCF9F2",
    },

    {
      id: 3,
      date: "2015-2017",
      title: "Best Freelancer",
      place: "Fiver & Upwork Level 2 & Top Rated",
      bg: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "ReactJs",
      number: "80",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "React Native",
      number: "80",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "NodeJs",
      number: "80",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Backend",
      number: "80",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    awardsArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
